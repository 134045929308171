<template>
  <div :class="classes" :data-preload="[$options.name]">
    <Placeholder
      v-for="(config, index) in content"
      :key="index"
      :config="config"
    />
  </div>
</template>

<script>
import Placeholder from 'Components/01-atoms/placeholder/Placeholder';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'Components',
  components: {
    Placeholder,
  },
  mixins: [globalMixin],
  props: {
    content: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.components {
  width: 100%;
}
</style>
